import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "./TeacherPage.css";

import logo from "../../images/logo.png";
import TeacherBadge from "../badge/TeacherBadge";
import ClassCard from "../card_classes/ClassCard";

function TeacherPage() {
  return (
    <>
      <Container className="head_purp" fluid>
        <Row>
          <Col sm={2}></Col>
          <Col sm={2}>
            Електронні додатки "Грамота"
            <div className="logo">
              <a href="/">
                <img alt="logo" src={logo} style={{ width: "50%" }} />
              </a>
            </div>
          </Col>
          <Col> Пошук</Col>

          <Col sm={3}>
            <div>Інструкція Служба підтримки</div> <br />
            <div>
              Ви увійшли як <TeacherBadge />
            </div>
            <div> Кравченко Інна Степанівна</div>
          </Col>
          <Col sm={2}></Col>
        </Row>
      </Container>

      <Container className="con_purp" fluid>
        <Row>
          <Col>
            <br />
          </Col>
        </Row>
        <Row>
          <Col xl={2}></Col>
          <Col>
            <ClassCard />
          </Col>
          <Col>
            <ClassCard />
          </Col>
          <Col>
            <ClassCard />
          </Col>
          <Col>
            <ClassCard />
          </Col>
          <Col xl={2}></Col>
        </Row>
      </Container>
    </>
  );
}

export default TeacherPage;
